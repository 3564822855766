import UserService from '@/services/user.service';
import TransferService from '@/services/transfer.service';
import socketClient from '../../services/socket/SOCKET';

import FileInput from '@/components/Inputs/FileInput';
import { Select, Option } from 'element-ui';
import NotiModal from '@/components/Modal/NotificationModal/NotificationModal.vue';
import ToggleButton from '@/components/ToggleButton/ToggleButton.vue';

import clientPaginationMixin from '@/mixin/clientPaginationMixin';
import autoBankMixin from '@/mixin/autoBankMixin';
import autoAddressMixin from '@/mixin/autoAddressMixin';
import postcodeMixin from '@/mixin/postcodeMixin';
import { mapState } from 'vuex';
import CommonConst from '@/util/const';

const wanakana = require('wanakana');
export default {
	name: 'MyPage',
	mixins: [clientPaginationMixin, autoBankMixin, autoAddressMixin, postcodeMixin],
	components: {
		FileInput,
		[Select.name]: Select,
		[Option.name]: Option,
		NotiModal,
		ToggleButton,
	},
	data() {
		return {
			infoFromDatabase: {},
			notiModalId: 'my-page-noti',
			notiContent: '',
			notiSingleBtn: '',
			pwdRegex: CommonConst.PWD_REGEX,
			model: {
				registerName: undefined,
				companyName: undefined,
				postcode: undefined,
				address: undefined,
				clientRemark: undefined,
				phoneNumber: undefined,
				faxNumber: undefined,
				mailAddress: undefined,
				loginId: undefined,
				loginPassword: undefined,
				confirmLoginPassword: undefined,
				bankName: undefined,
				bankBranchName: undefined,
				bankAccountType: undefined,
				bankAccountHolder: undefined,
				bankAccountNumber: undefined,
				webSiteName: undefined,
				instagramAccount: undefined,
				facebookAccount: undefined,
				twitterAccount: undefined,
				imprint: undefined,
				projectRemark: undefined,
				// An added area
				postcodeAddressJapan: '',
				// An added area
			},
			sealSrc: '',
			confirmLoginPassword: undefined,
			randomPassword: Math.random().toString(36).slice(2, 10),
			classificationSelect: '',
			loginUserId: '',
			radioOptions: [
				{
					text: '法人',
					value: '2',
				},
				{
					text: '個人',
					value: '1',
				},
			],
			bankAccountTypeOptions: [
				{
					value: '普通',
				},
				{
					value: '当座',
				},
			],
			lineVerifyUrl: '',
			textContent: 'URLをコピー',
			copyBtn: 'copy-btn',
			editSuccessMessage: '変更が完了しました',
			editErrorMessages: '変更できません',
			alertMessage: '',
			alertType: '',
			showAlert: false,
			editType: 0,
			isSoundEnabled: true,
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState(['userInfo']),
	},

	methods: {
		checkPhoneNumber() {
			if (this.model.phoneNumber) {
				this.model.phoneNumber = this.model.phoneNumber.replaceAll('-', '');
			}
		},
		checkFaxNumber() {
			if (this.model.faxNumber) {
				this.model.faxNumber = this.model.faxNumber.replaceAll('-', '');
			}
		},
		checkPostCodeNumber() {
			if (this.model.postcode) {
				this.model.postcode = this.model.postcode.replaceAll('-', '');
			}
		},
		onKeyDown(event) {
			const acceptedKeys = [
				'Backspace',
				'Delete',
				'ArrowLeft',
				'ArrowRight',
				'Minus',
				'NumpadSubtract',
			];
			if (
				(isNaN(Number(event.key)) && !acceptedKeys.includes(event.code)) ||
				event.code == 'Space'
			) {
				event.preventDefault();
			}
		},
		onCopy(copyData, inputIdx) {
			const copyDataFormatted = `${CommonConst.PRE_TEXT_LINE_LINK}${copyData}`;
			let self = this;
			this.$copyText(copyDataFormatted).then(
				function (e) {
					self.textContent = 'コピー済み';
					setTimeout(() => {
						self.textContent = 'URLをコピー';
					}, 1000);
					console.log(e);
				},
				function (e) {
					self.textContent = 'コピー失敗';
					console.log(e);
				}
			);
		},
		onBankAccountHolderChange() {
			this.model.bankAccountHolder = wanakana.toKatakana(this.model.bankAccountHolder, {
				IMEMode: true,
			});
		},
		isNumeric(evt) {
			var theEvent = evt || window.event;
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
			var regex = /[0-9]/;
			if (!regex.test(key)) {
				theEvent.returnValue = false;
				if (theEvent.preventDefault) theEvent.preventDefault();
			}
		},
		maxLengthCheck() {
			if (this.model.bankAccountNumber > 7) {
				this.model.bankAccountNumber = this.model.bankAccountNumber.slice(0, 7);
			}
		},

		async checkEditType() {
			let samePass = false;
			if (this.model.loginPassword == this.randomPassword) {
				samePass = true;
			} else {
				let response = await UserService.verifyPassword(
					this.userInfo.id,
					this.model.loginPassword
				);
				samePass = response[0].isCorrect;
			}
			let sameName = this.model.registerName === this.userInfo.register_name;
			let sameMail = this.model.mailAddress === this.userInfo.email_address;
			// 0: All, 1: Pass + Name, 2: Pass + Mail, 3: Name + Mail, 4: Pass, 5: Name, 6: Mail , 7: None
			if (samePass && sameName && sameMail) this.editType = 0;
			else if (samePass && sameName && !sameMail) this.editType = 1;
			else if (samePass && !sameName && sameMail) this.editType = 2;
			else if (!samePass && sameName && sameMail) this.editType = 3;
			else if (samePass && !sameName && !sameMail) this.editType = 4;
			else if (!samePass && sameName && !sameMail) this.editType = 5;
			else if (!samePass && !sameName && sameMail) this.editType = 6;
			else this.editType = 7;
		},

		async modalContent() {
			await this.checkEditType();
			return this.editType == 0
				? '変更します。\nよろしいですか？'
				: this.editType == 1
				? 'メールを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 2
				? 'お名前を変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 3
				? 'パスワードを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 4
				? 'お名前、メールを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 5
				? 'メール、パスワードを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 6
				? 'お名前、パスワードを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: 'お名前、メール、パスワードを変更した場合、\n即時で再度ログインが必須です。\n変更しますか？';
		},

		async onEditMyPage() {
			this.notiSingleBtn = false;
			this.notiContent = await this.modalContent();
			this.$bvModal.show(`${this.notiModalId}`);
		},

		onAccept() {
			this.editMyPageInfo();
		},

		async editMyPageInfo() {
			this.model.postcodeAddressJapan = this.convertToHalfWidth(
				this.model.postcodeAddressJapan
			);
			let newClientInfo = {
				account_holder: this.model.bankAccountHolder,
				account_number: this.model.bankAccountNumber,
				account_type: this.model.bankAccountType,
				company_name: this.model.companyName,
				address: this.model.address,
				bank_name: this.model.bankName,
				branch_name: this.model.bankBranchName,
				email_address: this.model.mailAddress,
				fax_number: this.model.faxNumber,
				phone_number: this.model.phoneNumber,
				// postal_code: this.model.postcode,
				postal_code: this.model.postcodeAddressJapan,
				remark: this.model.remark,
				update_user: this.loginUserId,
				username: this.model.loginId,
				company_seal: this.model.companySeal,
				login_password:
					this.model.loginPassword == this.randomPassword
						? undefined
						: this.model.loginPassword,
				type: this.classificationSelect,
				register_name: this.model.registerName,
			};
			console.log("this.infoFromDatabase", this.infoFromDatabase);
			console.log("newClientInfo", newClientInfo);
			let contents = this.deleteNullFields(newClientInfo);
			let [response, error] = await await UserService.update(
				this.userInfo.id,
				contents
			);
			if (response) {
				console.log('Edit My Page Success');
				this.sendNotificationToClientApp();
				if (this.editType != 0) {
					document.cookie =
						'access_token_cookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
					this.$router.push({ path: '/adminApp/login' });
				} else {
					this.notiSingleBtn = true;
					this.notiContent = '最新情報に変更されました。';
					this.$bvModal.show(`${this.notiModalId}`);
					this.userInfo.register_name = this.model.registerName;
					this.$store.commit('setUserInfo', this.userInfo);
				}
			} else {
				this.showAlert = true;
				this.alertType = 'danger';
				this.alertMessage = this.editErrorMessages;
			}
		},

		sendNotificationToClientApp() {
			const userId = this.$route.params.userId;

			socketClient.send('data_transfer', {
				data: {
					user_id: userId,
					event_name: 'update_creator_info',
				},
				room: 'user' + userId,
			});
		},

		async getLineVerifyUrl() {
			if (this.userInfo['line_verify_code']) {
				let [response, error] = await UserService.getLineVerifyUrl(this.userInfo['id']);
				if (response) {
					this.lineVerifyUrl = response.line_verify_url;
				}
				return;
			}
			let [response, error] = await UserService.createLineVerifyUrl(this.userInfo.id);
			if (response) {
				this.lineVerifyUrl = response.line_verify_url;
			}
		},

		async getUserInfo() {
			this.model.registerName = this.userInfo.register_name;
			this.model.companyName = this.userInfo.company_name;
			// this.model.postcode = this.userInfo.postal_code;
			this.model.postcodeAddressJapan = this.userInfo.postal_code;
			this.model.address = this.userInfo.address;
			this.model.remark = this.userInfo.remark;
			this.model.phoneNumber = this.userInfo.phone_number;
			this.model.faxNumber = this.userInfo.fax_number;
			this.model.mailAddress = this.userInfo.email_address;
			this.model.loginId = this.userInfo.username;
			this.model.loginPassword = this.userInfo.login_password;
			this.model.bankName = this.userInfo.bank_name;
			this.model.bankBranchName = this.userInfo.branch_name;
			this.model.bankAccountType = this.userInfo.account_type;
			this.model.bankAccountHolder = this.userInfo.account_holder;
			this.model.bankAccountNumber = this.userInfo.account_number;
			this.model.projectRemark = this.userInfo.remark;
			this.classificationSelect = this.userInfo.type;
			this.model.companySeal = this.userInfo.company_seal
				? this.userInfo.company_seal
				: undefined;
			this.model.loginPassword = this.randomPassword;
			this.confirmLoginPassword = this.randomPassword;
			this.isSoundEnabled = !!this.userInfo.sound_enabled;
			this.getCompanySeal();
			this.getLineVerifyUrl();

			this.infoFromDatabase = {...this.model}
		},

		deleteNullFields(obj) {
			for (let key in obj) {
				if ((obj[key] == undefined || obj[key] == '') && !CommonConst.IGNORE_FIELD.includes(key)) {
					if (key === 'account_number') {
						obj[key] = null;
					} else {
						delete obj[key];
					}
				}
			}
			return obj;
		},
		async getCompanySeal() {
			if (this.userInfo.company_seal && this.userInfo.company_seal.key) {
				let [responseTransferGet, errorTransfer] = await TransferService.get(
					this.userInfo.company_seal.key,
					CommonConst.EXPIRATION
				);
				if (responseTransferGet) {
					this.sealSrc = responseTransferGet.link;
					this.model.companySeal.link = responseTransferGet.link;
				} else {
					console.log(errorTransfer);
				}
			}
		},
		onUploadFile(files) {
			[...files].forEach((file) => {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async () => {
					let [uploadResponse, errorPostImg] = await await TransferService.postImg({
						content: reader.result.toString(),
						file_name: file.name,
						user_id: this.userInfo.id,
					});
					if (uploadResponse) {
						let key = uploadResponse.key;
						this.getLinkPreview(file, key);
					} else {
						console.log(errorPostImg);
					}
				};
			});
		},

		async getLinkPreview(file, key) {
			let [getResponse, errorGet] = await await TransferService.get(
				key,
				CommonConst.EXPIRATION
			);
			if (getResponse) {
				let linkPreview = getResponse.link;
				this.model.companySeal = {
					link: linkPreview,
					file_name: file.name,
					key: key,
				};
				this.sealSrc = linkPreview;
			} else {
				console.log('errorGet', errorGet);
			}
		},
		onAlertVisibleChange(data) {
			this.showAlert = data;
		},
		async checkEmailValidate(userId, email) {
			let [response, error] = await await UserService.checkExists({
				email_address: email,
			});
			if (error) {
				console.log(error);
			}
			if (response) {
				if (response.id == userId) {
					return true;
				}
				return !response.exists;
			}
		},

		async onChangeSoundEffect(data) {
			this.isSoundEnabled = data.value;

			const [response, error] = await await UserService.update(this.userInfo.id, {
				sound_enabled: data.value ? 1 : 0,
			});

			if (response) {
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userInfo?.id,
						event_name: 'toggle_creator_sound_enabled',
						content: data.value,
					},
					room: 'user' + this.userInfo?.id,
				});
			}
		},
	},

	async mounted() {
		this.getUserInfo();
	},
};
